import {
  CUSTOMER_ADDRESSES_PERMISSION, CUSTOMER_CONTACTS_PERMISSION,
  CUSTOMER_GENERAL_INFO_PERMISSION, CUSTOMER_VERIFICATION_PERMISSION,
  CUSTOMER_ACTIVITY_LOG_PERMISSION, CUSTOMER_FINANCIAL_INFO_PERMISSION,
  CUSTOMER_SALES_BY_ORDER_PERMISSION,
  CUSTOMER_ATTACHMENTS_PERMISSION,
} from '@/views/components/sub-menu/meta/main-sub-menu/home-customers'
// eslint-disable-next-line import/prefer-default-export
export default function mainCustomersMenuItems(active, params = {}, create = false) {
  const menuItems = [
    {
      name: create ? 'home-customers-create' : 'home-customers-general-info',
      layout: 'General Info',
      ...CUSTOMER_GENERAL_INFO_PERMISSION,
    },
    {
      name: 'home-customers-addresses',
      layout: 'Addresses',
      ...CUSTOMER_ADDRESSES_PERMISSION,
    },
    {
      name: 'home-customers-contacts',
      layout: 'Contacts',
      ...CUSTOMER_CONTACTS_PERMISSION,
    },
    {
      name: 'home-customers-verification',
      layout: 'Verification',
      ...CUSTOMER_VERIFICATION_PERMISSION,
    },
    {
      name: 'home-customers-financial-info',
      layout: 'Financial Info',
      ...CUSTOMER_FINANCIAL_INFO_PERMISSION,
    },
    {
      name: 'home-customers-sls-by-order',
      layout: 'Order History',
      ...CUSTOMER_SALES_BY_ORDER_PERMISSION,
    },
    {
      name: 'home-customers-attachments',
      layout: 'Attachments',
      ...CUSTOMER_ATTACHMENTS_PERMISSION,
    },
    {
      name: 'home-customers-activity-log',
      layout: 'Activity Log',
      ...CUSTOMER_ACTIVITY_LOG_PERMISSION,
    },
  ]
  menuItems.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.active = item.name === active
    // eslint-disable-next-line no-param-reassign
    item.route = { name: item.name, params }
    // eslint-disable-next-line no-param-reassign
    item.disabled = (create && item.name !== active)
  })
  return {
    menuItems,
  }
}
