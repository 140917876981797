<template>
  <div>
    <sub-menu-component
      v-if="!isFetching"
      :menu-items="menuItems"
    >
      <router-view @updateMenu="updateMenu" />
    </sub-menu-component>
    <div v-else>
      <div v-if="!error">
        <b-core-skeleton />
      </div>
      <div v-else>
        <component-not-found
          :button-link="{ name: 'home-customers'}"
          title="Customer List"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mainCustomersMenuItems from '@/views/components/sub-menu/main-sub-menu/home-customers'
import { ref } from '@vue/composition-api'

export default {
  name: 'CustomerView',
  data() {
    return {
      error: false,
      isFetching: true,
    }
  },
  computed: {
    customer() {
      return this.$store.state[this.MODULE_NAME].customer
    },
  },
  created() {
    this.isFetching = true
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(() => {
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    }).finally(() => {
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_STATUS`, this.customer.customer_detail?.status)
      this.isFetching = false
    })
  },
  methods: {
    updateMenu(active) {
      this.menuItems = mainCustomersMenuItems(active).menuItems
    },
  },
  setup() {
    const menuItems = ref()
    const MODULE_NAME = 'customers'
    return {
      menuItems,
      MODULE_NAME,
    }
  },
}
</script>

<style scoped>

</style>
