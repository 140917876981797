// MAIN SECTION SUBMENU ITEMS PERMISSIONS ====>

export const CUSTOMER_MAIN_LIST_PERMISSION = {
  action: 'view',
  resource: 'Main List',
}
export const CUSTOMER_GENERAL_INFO_PERMISSION = {
  action: 'view',
  resource: 'General Info',
}
export const CUSTOMER_ADDRESSES_PERMISSION = {
  action: 'view',
  resource: 'Addresses',
}
export const CUSTOMER_CONTACTS_PERMISSION = {
  action: 'view',
  resource: 'Contacts',
}
export const CUSTOMER_VERIFICATION_PERMISSION = {
  action: 'view',
  resource: 'Verification',
}
export const CUSTOMER_FINANCIAL_INFO_PERMISSION = {
  action: 'view',
  resource: 'Financial Info',
}
export const CUSTOMER_SALES_BY_ORDER_PERMISSION = {
  action: 'view',
  resource: 'Sales By Order',
}
export const CUSTOMER_ATTACHMENTS_PERMISSION = {
  action: 'view',
  resource: 'Attachments',
}
export const CUSTOMER_ACTIVITY_LOG_PERMISSION = {
  action: 'view',
  resource: 'ActivityLog',
}
